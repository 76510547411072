<header class="layout-grid">
    <mat-toolbar fixedInViewPort>
        <div class="header__logo">
            <a routerLink="/dashboard"><img [src]="'assets/images/logo.png'" alt="Teller Online Admin Portal"></a>
        </div>
        <nav aria-label="Primary" class="navigation">
            <ng-container *ngIf="authService.isAuthenticated">
                <a mat-button 
                    routerLink="/payments" 
                    routerLinkActive="navigation-link--active" 
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="navigation-link"
                    [attr.aria-current]="appService.ariaCurrent('/payments', true)">
                    <mat-icon svgIcon="select-payment" class="teller-online-icon"></mat-icon>
                    <span>Payments</span>
                </a>

                <a mat-button
                    *ngIf="siteMetadataService.authenticationEnabled"
                    routerLink="/users"  
                    routerLinkActive="navigation-link--active"
                    class="navigation-link"
                    [attr.aria-current]="appService.ariaCurrent('/users')">
                    <mat-icon svgIcon="user" class="teller-online-icon"></mat-icon>
                    <span>Users</span>
                </a>
                
                <button mat-button [matMenuTriggerFor]="profilenav" class="navigation-group" [ngClass]="{'navigation-group--active': appService.currentPage('/profile')}">
                    <mat-icon svgIcon="logout" class="teller-online-icon"></mat-icon>
                    {{ (authService.currentUser$ | async).name }}
                    <mat-icon>expand_more</mat-icon>
                </button>

                <mat-menu #profilenav="matMenu" class="navigation">
                    <button mat-menu-item (click)="onClick_signOut()" class="navigation-link">Sign Out</button>
                </mat-menu>
            </ng-container>
        </nav>
    </mat-toolbar>
</header>
