// Angular Files
import { Component } from '@angular/core';

// Teller Online Files
import { AuthService } from 'apps/admin-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineAppService, TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    host: {
        class: 'header'
    }
})
export class HeaderComponent {
    constructor(
        public authService: AuthService,
        public appService: TellerOnlineAppService,
        public siteMetadataService: TellerOnlineSiteMetadataService) {
    }

    public onClick_signOut(): void {
        this.authService.signOut();
    }
}
