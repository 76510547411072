// Angular Files
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';

// Teller Online Core Files
import { SearchSettingsDto, SearchFilterDto } from './api/CoreWebApi';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';

// based off of guidelines from https://blog.angular-university.io/angular-material-data-table/
export class TellerOnlineDataSource<T, 
                                    R extends TellerOnlineFilteredResponseModel<T>, 
                                    S extends TellerOnlineDataSourceCompatible<T, R>> 
       implements DataSource<T> {
    private _data = new BehaviorSubject<T[]>([]);
    private _loading = new BehaviorSubject<boolean>(false);
    private _totalResults = 0;

    public loading$ = this._loading.asObservable();

    public get hasData() {
        return this._data.value.length > 0;
    }

    public get totalResultCount() {
        return this._totalResults;
    }
    
    constructor(private service: S) {}

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this._data.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._data.complete();
        this._loading.complete();
    }

    /**
     * Load a new set of data based on the search settings.
     * @param settings Search settings to filter data on the backend
     * @param param Additional optional parameter for the request
     * @returns Promise with the newly loaded response
     */
    load(settings: SearchSettingsModel, param?: any): Promise<void | TellerOnlineFilteredResponseModel<T>> {
        this._loading.next(true);

        let loadPromise = this.service.loadDataSource(settings, param)
                                      .pipe<TellerOnlineFilteredResponseModel<T>, TellerOnlineFilteredResponseModel<T>>(
                                            catchError(() => of(new TellerOnlineFilteredResponseModel<T>())), //TODO: Have this emit an error message
                                            finalize(() => this._loading.next(false)))
                                      .toPromise();
        // we need to do the "then" portion separtely otherwise the return value won't work properly for the resoler
        loadPromise.then(results => {
            this._data.next(results.results);
            this._totalResults = results.totalResults;

            // If the page no longer exists, return to page one (this is rare)
            if (settings.pageIndex * settings.pageSize >= results.totalResults) {
                settings.pageIndex = 0;
                settings.save();
            }
        });
        // return the promise so it can be used in a resolver (not necessary otherwise)
        return loadPromise;
    }
}

export interface TellerOnlineDataSourceCompatible<T, R extends TellerOnlineFilteredResponseModel<T>> {
    loadDataSource(settings: SearchSettingsModel, param?: any): Observable<R>;
} 

/**
 * A class that is a generic mirror to any results that will populate a filterable table
 */
export class TellerOnlineFilteredResponseModel<T> {
    results: T[];
    totalResults: number;

    constructor(options: {results: T[], totalResults: number} = {results: [], totalResults: 0}) {
        this.results = options.results;
        this.totalResults = options.totalResults;
    }
}


export class SearchSettingsModel {
    search?: string;
    sortColumn?: string;
    sortDirection?: string;
    pageIndex: number = 0;
    pageSize: number = 10;
    searchFilters: SearchFilterOption[] = []

    /**
     * Creates a new search settings model and loads in any existing settings from local storage
     * based on the provided localstoragekey
     * @param windowService reference to the TellerOnlineWindowService to access local storage
     * @param localStorageKey the specific key used to save and load settings into/from local storage
     */
    constructor (private windowService?: TellerOnlineWindowService, private localStorageKey?: string) {
        if (this.windowService && this.localStorageKey) {
            this.load();
        }
    }

    public toString() {
        return JSON.stringify(this);
    }

    public fromJSON(value: string) {
        let json = JSON.parse(value);
        if(json){
            Object.keys(json).forEach(element => {
                if(json[element] && element != 'windowService') this[element] = json[element];
            });
        }
    }

    public toDto(): SearchSettingsDto {
        let filters: SearchFilterDto[] = [];
        this.searchFilters.forEach(sf => {
            sf.filters.forEach(f => {
                filters.push(new SearchFilterDto(f));
            });
        });
        return new SearchSettingsDto({
            sortColumn: this.sortColumn ?? "",
            sortDirection: this.sortDirection ?? "",
            search: this.search ?? "",
            filters: filters,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    /**
     * Adds a filter to the list of searchFilters as long as it's not already in the list
     * @param filter the filter to add to the list of filters
     */
    public addFilter(filter: SearchFilterOption) {
        if(!this.searchFilters.find(f => f.label == filter.label)) {
            this.searchFilters.push(filter);
        }
    }

    /**
     * Save the settings to local storage for later retrievel using the localStorageKey defined on creation
     * OR the optional overrideKey provided when calling save. .
     * Will error if no localStorageKey was defined on creation AND no overrideKey was passed in.
     * Useful when wanting to create a one time config that overrides an existing config without loading existing settings
     * @param overrideKey A key to use instead of the localStorageKey when saving the settings to local storage
     */
    public save(overrideKey: string = null) {
        this._throwError("save", overrideKey);
        this.windowService.setLocalStorageItem(overrideKey ?? this.localStorageKey, this.toString());
    }

    /**
     * Loads existing settings from local storage using the localStorageKey defined on creation.
     * Will error if no localStorageKey was defined
     */
    public load() {
        this._throwError("load");
        this.fromJSON(this.windowService.getLocalStorageItem(this.localStorageKey));
    }

    /**
     * Throws an error if windowService is not defined or
     * if localStorageKey is not defined on creation and no overrideKey was passed when trying to call the function
     * @param fncName Name of the function that threw the error
     * @param overrideKey Overridekey passed when calling the original function
     */
    private _throwError(fncName: string, overrideKey?: string) {
        if (!this.windowService) {
            throw new Error("TellerOnlineWindowService must be defined to call " + fncName);
        }
        if (!this.localStorageKey && !overrideKey) {
            throw new Error("localStorageKey must be defined to call " + fncName);
        }
    }
}

export class SearchFilterOption{
    /** The value to display to the user */
    label: string;
    /** The filters that are represented by this filter option */
    filters: SearchFilterModel[];
    /** The filter group that a filter belongs to */
    filterGroup: SearchFilterGroupEnum;
}

export enum SearchFilterGroupEnum {
    Amount = 'Amount',
    Status = 'Status',
    StatusGroup = 'Status Group',
    PaymentDate = 'Payment Date'
}

export class SearchFilterModel extends SearchFilterDto {}
