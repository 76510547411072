<div class="status-help-dialog__header">
    <h2 mat-dialog-title class="status-help__header__title">Status Help</h2>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div> 
<mat-dialog-content>
    <div class="status-help-dialog__status" *ngFor="let status of statusInfo">
        <h3 class="status-help-dialog__status__name">{{ status.name }}</h3>
        <p class="status-help-dialog__status__info" [innerHtml]="status.info"></p> 
    </div>
</mat-dialog-content>
