// Angular Files
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineRailNavigationService {
    // Public Variables
    public elements: TellerOnlineRailNavigationElement[] = [];

    // Private Variables
    private _enabled: boolean = false;

    public get enabled() {
        return this._enabled;
    }

    constructor() {
    }

    public addNavigation(elements: TellerOnlineRailNavigationElement[]) {
        this.elements = elements;
        this._enabled = true;
    }

    public removeNavigation() {
        this._enabled = false;
        this.elements = [];
    }
}

export class TellerOnlineRailNavigationElement {
    icon?: string;
    svgIcon?: string;
    link: string;
    disabled?: boolean = false;
    tooltip?: string;
    text: string;
}