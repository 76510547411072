// Angular Files
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";

// Angular Material Files

// Other External Files
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthConfig } from 'angular-oauth2-oidc';

// Teller Online Files
import { CoreModule } from "apps/admin-portal/src/app/core/core.module";
import {
    AdConfigDto,
    AuthApiClient,
    CurrentUserDto,
    SignInRequestDto,
    SignInResponseDto,
} from "apps/admin-portal/src/app/core/api/AdminPortalApiClients";

// Teller Online Library Files
import { TellerOnlineHistoryService, TellerOnlineXsrfService } from "teller-online-libraries/core";
import { TellerOnlineWindowService } from "teller-online-libraries/shared";
@Injectable({
    providedIn: CoreModule
})
export class AuthService implements HttpInterceptor {
    private _currentUser = new BehaviorSubject<CurrentUserModel>(null);
    public currentUser$ = this._currentUser.asObservable();

    public showLoginPrompt$ = new BehaviorSubject<boolean>(false);
    public adConfigModel: AdConfigModel;
    public authCodeFlowConfig: AuthConfig;
    private lastActiveTime: Date;
    private tokenExpirationMinutes: number = 15;

    constructor(
        private router: Router,
        private authApiClient: AuthApiClient,
        private xsrfService: TellerOnlineXsrfService,
        private windowService: TellerOnlineWindowService,
        private historyService: TellerOnlineHistoryService
    ) { }

    // This only applies to calls made via an HttpClient
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = this.getToken();
        const jwt = this.getJwt();
        let authReq: HttpRequest<any>;
        if (token || jwt) {
            let headers = {};
            this.setHeaders(headers);

            authReq = req.clone({
                setHeaders: headers,
            });
        }

        // Intercept the response and set the token expiration
        return next.handle(authReq || req).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                let httpResponse: HttpResponse<any> = event;
                let tokenExpiration = httpResponse.headers.get('tokenexpiration');

                // Avoid setting tokenExpiration to null because it causes
                // the user to be repeatedly kicked out of the site
                if (tokenExpiration) {
                    this.windowService.setLocalStorageItem("tokenExpiration", tokenExpiration);
                }
            }
        }));
    }

    public get currentUser(): CurrentUserModel {
        return this._currentUser.getValue();
    }

    public get isAuthenticated() {
        return this.currentUser?.isSignedIn;
    }

    public get tellerLogon() {
        return this.windowService.getSessionStorageItem('tellerLogon') == 'Y';
    }

    public async signIn(signInRequest?: SignInRequestModel) {
        const response = await this.authApiClient.signIn(signInRequest).toPromise();

        if (response.success) {
            await this._setupSignedInUser(signInRequest, response);
        }

        return response;
    }

    /** Redirect to the hold page in order to sign the user out from the third
        party AD service and take them to the sign in page for that service */
    public adSignOut() {
        return this.router.navigate(['/hold-page'], { state: { logout: 'true' } });
    }

    public async signOut() {
        await this.authApiClient.signOut().toPromise();
        this.localSignOut();
    }

    /** Performs all actions necessary to sign the user out on the client side.
     * Does not call the server. For full sign out, call signOut(). */
    public localSignOut() {
        // Remove the auth token from local storage
        this.windowService.removeLocalStorageItem("token");
        // Remove the auth token expiry from local storage
        this.windowService.removeLocalStorageItem("tokenExpiration");
        // Remove the user's ID from local storage
        this.windowService.removeLocalStorageItem("tellerOnlineAdminPortalUId");
        // Remove the user's stored payment settings
        this.windowService.removeLocalStorageItem("paymentsSettings");
        // Flag the user as signed out (for history purposes)
        this.historyService.signedOut = true;
        // Clear out the current user
        this._currentUser.next(null);
        // Close the popup
        this.showLoginPrompt$.next(false);
        //reset last active time
        this.lastActiveTime = null;
        // Redirect to sign in page
        if (this.adConfigModel.isAdEnabled && !this.tellerLogon) {
            this.adSignOut();
        } else {
            this.router.navigate(["/sign-in"]);
        }
    }

    public async getCurrentUser(): Promise<CurrentUserModel> {
        const response: CurrentUserModel = await this.authApiClient.currentUser().toPromise();
        if (response?.isSignedIn) {
            this._currentUser.next(response);
            await this._checkSessionExpiration();

            // Load the xsrf token so that all authenticated requests can use it.
            await this.xsrfService.loadXsrfToken();
        } else {
            this.localSignOut();
        }

        return response;
    }

    public getToken() {
        return this.windowService.getLocalStorageItem("token");
    }

    public getJwt() {
        return this.windowService.getLocalStorageItem("id_token");
    }

    /** Set any headers that are required for authentication
     * Used by the interceptor and in dashboardService for dataGrid
     */
    public setHeaders(headers) {
        if (this.getToken())
            headers["headertoken"] = this.getToken();
        if (this.getJwt())
            headers["headerjwt"] = this.getJwt();
    }

    public setLastActiveTime() {
        this.lastActiveTime = new Date(Date.now() - 10000);
    }

    public async getAdConfig() {
        if (!this.adConfigModel) {
            this.adConfigModel = await this.authApiClient.adConfig().toPromise();
            this.authCodeFlowConfig = {
                // Url of the Identity Provider and the Identity Provider's
                // logout endpoint
                issuer: this.adConfigModel.issuerUrl,
                logoutUrl: this.adConfigModel.logoutUrl,

                // URL of the Teller Online page to redirect to after login or logout
                // Logout needs to be explicitly set
                redirectUri: window.location.origin + '/hold-page',
                postLogoutRedirectUri: window.location.origin + '/hold-page',

                // The 3rd party application's Client ID
                clientId: this.adConfigModel.clientId,

                // Response type requested from the 3rd party application
                // Code selected as this library handles taking returned code and
                // Generating all necessary parameters in local storage
                responseType: 'code',

                // Set the scope for the permissions the client should request
                scope: 'openid profile email',

                // Required for Cyberark Discovery Document access
                strictDiscoveryDocumentValidation: false,

                // Required for local environments and testing only
                //requireHttps: false,
                //showDebugInformation: true,
            };
        }
        return this.adConfigModel;
    }

    public isSessionExpired() {
        let tokenExpiration = this.windowService.getLocalStorageItem("tokenExpiration")

        if (tokenExpiration) {
            // Check if session has timed out.
            const currentTime = new Date();
            const tokenExpirationTime = new Date(tokenExpiration);
            const secondsDifference = (tokenExpirationTime.getTime() - currentTime.getTime()) / 1000;
            // If JWT is expired then user must sign in through 3rd party again
            if (this.adConfigModel.isAdEnabled && !this.tellerLogon) {
                if (Number(this.windowService.getLocalStorageItem("id_token_expires_at")) < currentTime.getTime()) {
                    return SessionExpiryEnum.Expired;
                }
            }

            // tokenExpiration is null if the user has multiple tabs open and
            // the "Sign In Again" prompt has appeared on one tab but not the others.
            // If it is not valid then we will assume the user's session is expired and prompt them to re-authenticate.
            let isTokenExpirationValidDate = tokenExpirationTime instanceof Date && !isNaN(Number(tokenExpirationTime));
            if (!isTokenExpirationValidDate || secondsDifference < 45) {
                // lastActiveTime will be null if the user refreshes the page after the "Sign In Again" prompt
                // appears but before their session is actually expired.
                // We will assume the user's session is expired and prompt them to re-authenticate.
                let lastActionSecondDifference = this.tokenExpirationMinutes * 60 + 1;
                if (isTokenExpirationValidDate && this.lastActiveTime) {
                    lastActionSecondDifference = (tokenExpirationTime.getTime() - this.lastActiveTime.getTime()) / 1000;
                }

                // Check if any action was performed during session and either extend the session or prompt the user to sign in
                if (lastActionSecondDifference > (this.tokenExpirationMinutes * 60)) {
                    return SessionExpiryEnum.Expired;
                } else {
                    return SessionExpiryEnum.AlmostExpired;
                }
            }
            return SessionExpiryEnum.NotExpired;
        } else {
            return SessionExpiryEnum.NoSession;
        }
    }

    private async _setupSignedInUser(request: SignInRequestModel, response: SignInResponseDto) {
        // Store the user id so we can use it later to re-sign them back in
        if (!this.adConfigModel.isAdEnabled || this.tellerLogon) {
            this.windowService.setLocalStorageItem("tellerOnlineAdminPortalUId", request.userId);
        }
        this.windowService.setLocalStorageItem("token", response.token);

        // Load the current user into memory.
        await this.getCurrentUser();

        // dismiss the login prompt
        this.showLoginPrompt$.next(false);
    }

    private async _checkSessionExpiration() {
        if (!this.isAuthenticated) {
            return;
        }

        // Check again in 30 seconds.
        setTimeout(() => this._checkSessionExpiration(), 30000);

        let isExpired = this.isSessionExpired();

        switch (isExpired) {
            case SessionExpiryEnum.NoSession:
            case SessionExpiryEnum.Expired:
                // If we're not currently showing the login prompt, display the prompt and obfuscate the current page
                if (!this.showLoginPrompt$.value) {
                    this.showLoginPrompt$.next(true);
                }
                break;
            case SessionExpiryEnum.AlmostExpired:
                try {
                    await this._extendSession();
                } catch {
                    await this.signOut();
                }
                break;
            default:
                break;
        }
    }

    private async _extendSession() {
        await this.xsrfService.loadXsrfToken();
        return await this.authApiClient.extendSession().toPromise();
    }
}

export enum SessionExpiryEnum {
    Expired = "Expired",
    AlmostExpired = "AlmostExpired",
    NotExpired = "NotExpired",
    NoSession = "NoSession"
};
export class SignInRequestModel extends SignInRequestDto { };
export class CurrentUserModel extends CurrentUserDto { };
export class AdConfigModel extends AdConfigDto { };
