// Angular Files
import { Injectable } from '@angular/core';

// Other External Files
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Teller Online Files
import { SearchSettingsModel, TellerOnlineDataSourceCompatible, TellerOnlineFilteredResponseModel } from 'teller-online-libraries/core';
import { AdminUsersApiClient, FilteredUnconfirmedUsersResponseDto, UnconfirmedUserDto } from '../api/AdminPortalApiClients';

@Injectable({
    providedIn: 'root'
})
export class AdminUnconfirmedUsersService
        implements TellerOnlineDataSourceCompatible<UnconfirmedUserModel, TellerOnlineFilteredUnconfirmedUsersResponseModel> {
    
    constructor(private adminUsersApiClient: AdminUsersApiClient) {}

    /**
     * Get all unconfirmed user accounts.
     * @returns Promise which when fufilled provides the unconfirmed user accounts
     */
    public getUnconfirmedUsers(): Promise<FilteredUnconfirmedUsersResponseDto> {
        let settings = new SearchSettingsModel();
        settings.pageSize = 5; // only pull the first 5
        return this.adminUsersApiClient.getFilteredUnconfirmedUsers(settings.toDto()).toPromise();
    }

    /**
     * Load unconfirmed user accounts based on the current search settings specified by the user.
     * @param settings Search settings to pull from the data sourse (filters, search, etc...)
     * @returns An observable for the results from the request.
     */
    public loadDataSource(settings: SearchSettingsModel): Observable<TellerOnlineFilteredUnconfirmedUsersResponseModel> {
        return this.adminUsersApiClient.getFilteredUnconfirmedUsers(settings.toDto())
                                     .pipe(map(response => new TellerOnlineFilteredUnconfirmedUsersResponseModel({
                                            results: response.results.map(p => {
                                                return new UnconfirmedUserModel(p);
                                            }),
                                            totalResults: response.totalResults
                                        })
                                    ));
    }
}

export class TellerOnlineFilteredUnconfirmedUsersResponseModel extends TellerOnlineFilteredResponseModel<UnconfirmedUserModel> {}
export class UnconfirmedUserModel extends UnconfirmedUserDto {}

