<mat-nav-list class="rail-navigation-list navigation">
    <div mat-list-item 
        *ngFor="let railElement of railNavigationService.elements"
        [matTooltip]="railElement.tooltip">
        <a mat-list-item 
            [routerLink]="railElement.disabled ? null : railElement.link"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="rail-navigation-link--active"
            class="rail-navigation-link navigation-link"
            [disabled]="railElement.disabled"
            [attr.aria-current]="appService.ariaCurrent(railElement.link)">
            <mat-icon mat-line [svgIcon]="railElement.svgIcon" [ngClass]="{'teller-online-icon' : railElement.svgIcon}">{{railElement.icon}}</mat-icon>
            <span mat-line>{{railElement.text}}</span>
        </a>
    </div>
</mat-nav-list>