<h2 mat-dialog-title>
    Sign in again to continue
</h2>

<mat-dialog-content>
    <p>Your session has expired. In order to continue using the Admin Portal, you must sign in again.</p>
    <app-sign-in-form [userIdReadOnly]="true" [loaderOnSubmit]="false" [showSignOut]="true"></app-sign-in-form>
</mat-dialog-content>


