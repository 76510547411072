// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Core Files
import { UserProfileDto, UsersApiClient } from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineUsersService {
    constructor(
        private usersApiClient: UsersApiClient
    ) {}

    public async getUserProfile(userId: number): Promise<UserProfileModel> {
        return await this.usersApiClient.getUserProfile(userId).toPromise();
    }

    public async getPaymentMethods(userId) {
        return this.usersApiClient.getUserPaymentMethods(userId).toPromise();
    }
}

export class UserProfileModel extends UserProfileDto {}
