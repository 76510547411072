// Angular Files
import { Injectable } from '@angular/core';

// Other External Files
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Teller Online Files
import { AdminUsersApiClient, BasicUserHistoryDto, UnconfirmedUserDto } from 'apps/admin-portal/src/app/core/api/AdminPortalApiClients';

// Teller Online Library Files
import { 
    SearchSettingsModel, 
    TellerOnlineDataSourceCompatible, 
    TellerOnlineFilteredResponseModel, 
    UserProfileModel 
} from 'teller-online-libraries/core';


@Injectable({
    providedIn: 'root'
})
export class AdminUsersService 
       implements TellerOnlineDataSourceCompatible<UserProfileModel, TellerOnlineFilteredUsersResponseModel> {
    constructor(private adminUsersApiClient: AdminUsersApiClient) {
    }

    public getBasicUserHistory(userId: number): Promise<BasicUserHistoryModel> {
        return this.adminUsersApiClient.getBasicUserHistory(userId).toPromise();
    }

    public loadDataSource(settings: SearchSettingsModel): Observable<TellerOnlineFilteredUsersResponseModel> {
        return this.adminUsersApiClient.getFilteredUsers(settings.toDto())
                                     .pipe(map(response => {
                                        return new TellerOnlineFilteredUsersResponseModel({
                                            results: response.results.map(p => {
                                                return new UserProfileModel(p);
                                            }),
                                            totalResults: response.totalResults
                                        })
                                    }));
    }

    /**
     * Get the number of authenticated users in the current portal
     * @returns Total number of users that have been successfully authenticated
     */
    public getAuthenticatedUsersCount(): Promise<number> {
        let settings = new SearchSettingsModel();
        settings.pageSize = 0; // Just get the number, no actual results
        return this.adminUsersApiClient.getFilteredUsers(settings.toDto())
        .pipe(map(results => results.totalResults)).toPromise();
    }

    public getAnonymousUsersCount(): Promise<number> {
        return this.adminUsersApiClient.getAnonymousUsersCount().toPromise();
    }

    public getStaleUsersCount(): Promise<number> {
        return this.adminUsersApiClient.getStaleUsers().pipe(map(response => response.totalResults)).toPromise();
    }
}

export class BasicUserHistoryModel extends BasicUserHistoryDto {}

export class TellerOnlineFilteredUsersResponseModel extends TellerOnlineFilteredResponseModel<UserProfileModel> {}