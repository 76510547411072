// Angular Files
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Inject
} from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    RouterEvent,
    Router,
    RoutesRecognized
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

// Angular Material Files
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// Other External Files
import { Subscription, throwError } from 'rxjs';

// Teller Online Files
import { AuthService } from './core/services';

// Teller Online Library Files
import { TellerOnlineAppService, TellerOnlineRailNavigationService } from 'teller-online-libraries/core';
import { TellerOnlineIconsRegistryService } from 'teller-online-libraries/icons';
import { SignInDialogComponent } from './shared/components';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    // Private Variables
    private _loginDialog: MatDialogRef<SignInDialogComponent>;

    // Subscriptions
    private _routerEventSubscription: Subscription;
    private _showLoginPromptSubscription: Subscription;

	constructor(
        @Inject(DOCUMENT) private document: Document,
        private cdr: ChangeDetectorRef,
		private router: Router,
        // we need to inject it so that it will be constructed, even though it's "not being used"
        private iconRegistry: TellerOnlineIconsRegistryService,
        public appService: TellerOnlineAppService,
        public authService: AuthService,
        public railNavigationService: TellerOnlineRailNavigationService,
        public dialog: MatDialog
	) {
		this._routerEventSubscription = this.router.events.subscribe((event: RouterEvent) => {
            this.handleRouterEventSubscription(event);
        });

        this._showLoginPromptSubscription = this.authService.showLoginPrompt$.subscribe(prompt => {
            if(prompt) {
                this._loginDialog = this.dialog.open(SignInDialogComponent, {
                    closeOnNavigation: false,
                    disableClose: true
                });
                this.appService.finishPageLoading();
                this.appService.finishLoading();
            } else {
                if(this._loginDialog) this._loginDialog.close();
            }
        });
	}

    ngOnInit() {
        this.appService.finishLoading();
    }

    ngOnDestroy() {
        if (this._routerEventSubscription) this._routerEventSubscription.unsubscribe();
        if (this._showLoginPromptSubscription) this._showLoginPromptSubscription.unsubscribe();
    }

    handleRouterEventSubscription(event: RouterEvent) {
        switch (true) {
            case event instanceof RoutesRecognized:
                // Set the routeTitle to be used later when setDocumentTitle is called

                //@ts-ignore
                let routeTitle = event.state?.root.firstChild.data?.title;

                // Profile has it's own routing module, so when on child pages of profile,
                // use the titles from that routing component instead.
                if (event.url.startsWith('/profile')) {
                    //@ts-ignore
                    routeTitle = event.state?.root.firstChild.firstChild.firstChild.data?.title;
                }

                this.appService.setRouteTitle(routeTitle);
            case event instanceof NavigationStart:
                // If we are attempting to logout via AD close the login prompt or else the page won't load properly
                let state = this.router.getCurrentNavigation()?.extras.state;
                if(state?.logout) {
                    this.authService.showLoginPrompt$.next(false);
                }
                let loadingMessage = "Loading...";
                this.appService.triggerLoading(loadingMessage);
                break;
            case event instanceof NavigationError:
                // @ts-ignore
                throwError(event.error);
            case event instanceof NavigationCancel:
                // Reset the authService password anytime we successfully navigate somewhere so it will be empty for any future requests
                this.appService.finishLoading();
                this.cdr.detectChanges();
                break;
            case event instanceof NavigationEnd:
                let loadedMessage = "Loaded";

                // Update the title attribute for the page
                this.appService.setDocumentTitle();

                this.appService.finishLoading(loadedMessage);

                //@ts-ignore
                this.appService.currentUrl = event.urlAfterRedirects.replace(/\?tellerLogon=[YN]/, '');

                // remove the rail navigation if we're not on a rail page anymore
                if(!this.appService.railPage) this.railNavigationService.removeNavigation();

                // push out when we focus to ensure the html has actually rendered
                setTimeout(() => {
                    const mainFocus: HTMLElement = this.document.querySelector('#main-content-focus');

                    if (mainFocus) {
                        mainFocus.focus();
                    }
                }, 0);

                this.cdr.detectChanges();
                break;

            default: {
                break;
            }
        }
    }
}
