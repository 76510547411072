// Angular Files
import { Injectable } from "@angular/core";

// Other External Files
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// Teller Online Files
import {
    AdminPaymentsApiClient,
    PaymentAdminDetailsDto,
    PaymentsNextPrevDto,
    PaymentsSearchOptionsEnumDto,
    RefundPaymentRequestDto,
    CancelPaymentRequestDto
} from "apps/admin-portal/src/app/core/api/AdminPortalApiClients";

// Teller Online Library Files
import { 
    PaymentStatusEnum,
    PaymentStatusEnumConvertor,
    PaymentDetailsModel,
    PaymentOverviewModel, 
    SearchFilterModel, 
    SearchFilterGroupEnum,
    SearchSettingsModel, 
    TellerOnlineAppService, 
    TellerOnlineDataSourceCompatible, 
    TellerOnlineFilteredResponseModel, 
    TellerOnlinePaymentsService,
    TellerOnlineSiteMetadataService
} from "teller-online-libraries/core";
import { TellerOnlineMessageService } from "teller-online-libraries/shared";
import { PaymentMethodTypeEnum } from "apps/public-portal/src/app/payment-integrations";

@Injectable({
    providedIn: "root"
})
export class AdminPaymentsService
       implements TellerOnlineDataSourceCompatible<PaymentOverviewModel, TellerOnlineFilteredPaymentsResponseModel> {
    constructor(
        private adminPaymentsApiClient: AdminPaymentsApiClient,
        private messageService: TellerOnlineMessageService,
        private paymentsService: TellerOnlinePaymentsService,
        private appService: TellerOnlineAppService,
        private siteMetadataService: TellerOnlineSiteMetadataService
    ) {}
    
    public getPaymentAdminStatus(paymentIdentifier: string): Promise<PaymentAdminDetailsModel> {
        return this.adminPaymentsApiClient.getPaymentAdminStatus(paymentIdentifier).toPromise();
    }

    public loadDataSource(settings: SearchSettingsModel): Observable<TellerOnlineFilteredPaymentsResponseModel> {
        return this.adminPaymentsApiClient.getFilteredPayments(settings.toDto())
                                     .pipe(map(response => {
                                        return new TellerOnlineFilteredPaymentsResponseModel({
                                            results: response.results.map(p => {
                                                let overview = new PaymentOverviewModel(p);
                                                overview.flatten();
                                                return overview;
                                            }),
                                            totalResults: response.totalResults
                                        })
                                    }));
    }

    public getErroredPayments(): Promise<PaymentOverviewModel[]> {
        let settings = new SearchSettingsModel();
        settings.addFilter(ERROR_PAYMENTS_FILTER);
        settings.pageSize = 5; // only pull the first 5
        return this.adminPaymentsApiClient.getFilteredPayments(settings.toDto()).pipe(map(response => {
            return response.results.map(p => {
                let overview = new PaymentOverviewModel(p);
                overview.flatten();
                return overview;
            })
        })).toPromise();
    }

    /**
     * Get the number of successful payments in the current month
     * @returns Total number of finalized payments in the current month
     */
    public getMonthlyPaymentsCount(): Promise<number> {
        let date = new Date();
        let settings = new SearchSettingsModel();

        // Setup a filter for payments in the last month
        settings.addFilter({
            label: "Monthly Payments",  
            filters: [
                new SearchFilterModel({
                    type: PaymentsSearchOptionsEnumDto.StartDate,
                    value: new Date(date.getFullYear(), date.getMonth()).toDateString()
                }),
                new SearchFilterModel({
                    type: PaymentsSearchOptionsEnumDto.Status,
                    value: PaymentStatusEnumConvertor.toDto(PaymentStatusEnum.Finalized)
                })
            ],
            filterGroup: SearchFilterGroupEnum.PaymentDate
        });

        // This gives just the totalCount, no actual results in the response
        settings.pageSize = 0;

        return this.adminPaymentsApiClient.getFilteredPayments(settings.toDto())
        .pipe(map(response => response.totalResults)).toPromise();
    }

    public getNextPrevPayments(paymentIdentifier: string, settings: SearchSettingsModel): Promise<PaymentNextPrevModel> {
        return this.adminPaymentsApiClient.getNextPrevPayments(paymentIdentifier, settings.toDto()).toPromise();
    }

    public syncPaymentWithTeller(paymentIdentifier: string) {
        return this.adminPaymentsApiClient.syncPaymentWithTeller(paymentIdentifier).toPromise();
    }

    public cancelPayment(paymentIdentifier: string, request: CancelPaymentRequestModel) {
        return this.adminPaymentsApiClient.cancelPayment(paymentIdentifier, request).toPromise();
    }

    public markPaymentRefunded(paymentIdentifier: string, request: RefundPaymentRequestModel) {
        return this.adminPaymentsApiClient.markPaymentRefunded(paymentIdentifier, request).toPromise();
    }

    public async modifyPayment(paymentIdentifier: string, action: ModifyAction, comments: string = null): 
        Promise<{paymentDetails: PaymentDetailsModel, adminPaymentStatus: PaymentAdminDetailsModel}> {
        try {
            this.appService.triggerPageLoading();

            if(action != ModifyAction.Sync) {
                if(!comments) {
                    throw new Error("Comments are required.");
                }
            }
            
            switch(action){
                case ModifyAction.Sync:
                    await this.syncPaymentWithTeller(paymentIdentifier);
                    break;
                case ModifyAction.Cancel:
                    await this.cancelPayment(paymentIdentifier, new RefundPaymentRequestModel({comments: comments}));
                    break;
                case ModifyAction.Refund:
                    await this.markPaymentRefunded(paymentIdentifier, new CancelPaymentRequestModel({comments: comments}));
                    break;
            }
            this.messageService.notification("The requested action was completed successfully.", "success", 5000);
        } catch(e) {
            if(e?.errorDef == "CannotSyncCartInvalidStatus") {
                throw e;
            }
            this.messageService.notification("The payment was not Finalized. "+
                                                "Please check the Activity tab for more details.", "attention", 5000);
            // Don't do anything with the error, we just don't want to show it because it's catered towards the Public Portal
        } finally {
            // Now refresh the cart, whether it succeeded or not
            let paymentDetails = await this.paymentsService.getPaymentDetails(paymentIdentifier);
            let adminPaymentStatus = await this.getPaymentAdminStatus(paymentIdentifier);
            this.appService.finishPageLoading();

            // return the details tobe used by the calling method
            return {
                paymentDetails: paymentDetails, 
                adminPaymentStatus: adminPaymentStatus
            };
        }
    }

    public getConvenienceFeeLabel(paymentMethodType: PaymentMethodTypeEnum, configKey?: string) {
        const convenienceFeeConfigs = this.siteMetadataService.appConfiguration.convenienceFeeConfig;
        const config = convenienceFeeConfigs.find(c => c.configKey ?? "" == configKey);
        
        let label;

        // Grab tender specific label
        switch (paymentMethodType) {
            case PaymentMethodTypeEnum.CreditCard:
                label = config.creditConfig.convenienceFeeLabel;
                break;
            case PaymentMethodTypeEnum.ECheck:
                label = config.eCheckConfig.convenienceFeeLabel;
                break;
            default:
                break;
        }

        // Default label is always "Convenience Fee"
        return label ?? "Convenience Fee";
    }
}

export enum ModifyAction {
    Sync,
    Cancel,
    Refund
}

export const ERROR_PAYMENTS_FILTER = {
    label: "Errored",
    filters: [
        new SearchFilterModel({
            type: PaymentsSearchOptionsEnumDto.OneOfStatus,
            value: JSON.stringify([
                PaymentStatusEnumConvertor.toDto(PaymentStatusEnum.ProcessingFailed),
                PaymentStatusEnumConvertor.toDto(PaymentStatusEnum.PostingFailed),
            ])
        })
    ],
    filterGroup: SearchFilterGroupEnum.StatusGroup
}

export const TODAY_FILTER = {
    label: "Today",
    filters: [
        new SearchFilterModel({
            type: PaymentsSearchOptionsEnumDto.StartDate,
            value: (new Date()).toDateString()
        })
    ],
    filterGroup: SearchFilterGroupEnum.PaymentDate
}

export class RefundPaymentRequestModel extends RefundPaymentRequestDto {}
export class CancelPaymentRequestModel extends CancelPaymentRequestDto {}

export class PaymentAdminDetailsModel extends PaymentAdminDetailsDto {}

export class PaymentNextPrevModel extends PaymentsNextPrevDto {}

export class TellerOnlineFilteredPaymentsResponseModel extends TellerOnlineFilteredResponseModel<PaymentOverviewModel> {}



