// Angular Files
import { Component, OnInit } from '@angular/core';

// Teller Online Library Files
import { TellerOnlineAppService, TellerOnlineRailNavigationService } from '../../services';

@Component({
    selector: 'app-rail-navigation',
    templateUrl: './rail-navigation.component.html',
    styleUrls: ['./rail-navigation.component.scss'],
    host: {
        class: 'rail-navigation'
    }
})
export class TellerOnlineRailNavigationComponent {
    constructor(
        public railNavigationService: TellerOnlineRailNavigationService,
        public appService: TellerOnlineAppService
    ) { }
}
