// Angular Files
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from "@angular/router";

// Teller Online Files
import { CoreModule } from "apps/admin-portal/src/app/core/core.module";
import { AuthService } from "./auth.service";
import { TellerOnlineWindowService } from "teller-online-libraries/shared";

@Injectable({
    providedIn: CoreModule
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private windowService: TellerOnlineWindowService) { }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        return this.canActivate(route);
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        this.authService.setLastActiveTime();
        const isLoggedIn = this.authService.isAuthenticated;
        const isLoginForm = route.routeConfig.path === 'sign-in';
        const isHoldPage = route.routeConfig.path === 'hold-page';
        const adConfig = await this.authService.getAdConfig();

        if (route.queryParams["tellerLogon"] == 'Y') {
            this.windowService.setSessionStorageItem('tellerLogon', 'Y');
        } else if (route.queryParams["tellerLogon"] == 'N') {
            this.windowService.removeSessionStorageItem('tellerLogon');
        }



        if (adConfig.isAdEnabled && !isLoggedIn && !isHoldPage && !this.authService.tellerLogon) {
            this.router.navigate(['/hold-page'], {
                state: {
                    redirectToRoute: route.routeConfig.path
                }
            });
            return false;
        }

        // Do not check isHoldPage as the hold page handles redirection internally if user is already logged in
        if (isLoggedIn && isLoginForm) {
            this.router.navigate(['/']);
            return false;
        }

        if (!isLoggedIn && !isLoginForm && (!adConfig.isAdEnabled || this.authService.tellerLogon)) {
            this.router.navigate(['/sign-in'], {
                state: {
                    redirectToRoute: route.routeConfig.path
                }
            });
        }

        return isLoggedIn || isLoginForm || isHoldPage;
    }
}