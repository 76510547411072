<a class="skip-to-main-content" [href]="appService.currentUrl+'#maincontent'"
   *ngIf="!(appService.loading$ | async)"
   [attr.inert]="(appService.pageLoading$ | async) || (appService.loading$ | async) ? '' : null">Skip to Main Content</a>

<div class="app-container" [attr.aria-busy]="(appService.loading$ | async) ? 'true' : 'false'"
    [ngClass]="{
        'app-container--rail': appService.railPage
    }"
    [attr.inert]="(appService.pageLoading$ | async) || (appService.loading$ | async) ? '' : null">
    <app-header></app-header>
    <!-- Container for rail navigation -->
    <mat-sidenav-container class="inner-container">
        <!-- Rail navigation -->
        <mat-sidenav #sideNav
            fixedInViewport
            role="navigation"
            mode="side"
            [fixedTopGap]="64"
            [opened]="true"
            *ngIf="railNavigationService.enabled">
            <app-rail-navigation></app-rail-navigation>
        </mat-sidenav> <!-- End rail navigation -->

        <!-- Main application content -->
        <mat-sidenav-content class="main-layout">
            <main id="main-content" [ngClass]="{'debug': appService.debug}">
                <router-outlet *ngIf="!(appService.loading$ | async) && !(authService.showLoginPrompt$ | async)"></router-outlet>
                <article class="skeleton-content"></article>
            </main>
        </mat-sidenav-content> <!-- End main application content -->
    </mat-sidenav-container> <!-- End rail navigation container -->
</div>

<teller-online-load-panel></teller-online-load-panel>