// Angular Files
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

// Teller Online Files
import { CoreModule } from "apps/admin-portal/src/app/core/core.module";
import { UserApiClient, UserNotificationHistoryDto } from "../api/AdminPortalApiClients";

@Injectable({
    providedIn: CoreModule
})
export class UserService {
    currentUserId: string;
    currentUser = new Subject<any>();
    currentUser$ = this.currentUser.asObservable();

    constructor(
        private userApiClient: UserApiClient
    ) {}

    setCurrentUser(userData: any) {
        if (userData) {
            this.currentUserId = userData.identityUserId;
            this.currentUser.next(userData);
        }
    }

    public async getUserNotificationHistory(userId: number): Promise<UserNotificationHistoryModel[]> {
        return await this.userApiClient.getUserNotificationHistory(userId).toPromise();
    }
}

export class UserNotificationHistoryModel extends UserNotificationHistoryDto {}
