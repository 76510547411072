// Angular Files
import { Injectable } from '@angular/core';

// Other External Files
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

// Teller Online Files
import { CoreModule } from 'apps/admin-portal/src/app/core/core.module';
import { AdminPaymentsApiClient } from 'apps/admin-portal/src/app/core/api/AdminPortalApiClients';
import { ActivityLogDto } from 'apps/admin-portal/src/app/core/api/AdminPortalApiClients';

// Teller Online Library Files
import {
    SearchSettingsModel, TellerOnlineDataSourceCompatible, TellerOnlineFilteredResponseModel
} from "teller-online-libraries/core";

@Injectable({
    providedIn: CoreModule
})
export class AdminPaymentActivityService
        implements TellerOnlineDataSourceCompatible<ActivityLogModel, TellerOnlinePaymentActivityResponseModel> {
    constructor(private adminPaymentsApiClient: AdminPaymentsApiClient) {}

    public loadDataSource(settings: SearchSettingsModel, paymentIdentifier: string): Observable<TellerOnlinePaymentActivityResponseModel> {
        return this.adminPaymentsApiClient.getPaymentActivity(settings.toDto(), paymentIdentifier)
            .pipe(map(response => {
                return new TellerOnlinePaymentActivityResponseModel({
                    results: response.results.map(p => {
                        return new ActivityLogModel(p);
                    }),
                    totalResults: response.totalResults
                })
            }));
    }
}

export class ActivityLogModel extends ActivityLogDto {}

export class TellerOnlinePaymentActivityResponseModel extends TellerOnlineFilteredResponseModel<ActivityLogModel> {};