// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Component Files
import { SignInFormModule } from './sign-in-form/sign-in-form.component';
import { SignInDialogModule } from './sign-in-dialog/sign-in-dialog.component';
import { StatusHelpDialogModule } from './status-help-dialog/status-help-dialog.component';

@NgModule({
    imports: [
        StatusHelpDialogModule,
        SignInDialogModule,
        SignInFormModule
    ],
    exports: [
        StatusHelpDialogModule,
        SignInDialogModule,
        SignInFormModule
    ]
})
export class ComponentsModule { }