// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

// Teller Online Files
import { AuthGuardService, PortalFeatureGuardService } from "./core/services";

const routes: Routes = [
    {
        path: "hold-page",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./features/hold-page/hold-page.module").then(
                (m) => m.HoldPageModule
            ),
        data: {title: "Authenticating"}
    },
    {
        path: "users/unconfirmed",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./features/unconfirmed-users/unconfirmed-users.module").then(
                (m) => m.UnconfirmedUsersModule
            ),
        data: {title: "UnconfirmedUsers"}
    },
    {
        path: "payments/:uid",
        canActivateChild: [AuthGuardService],
        loadChildren: () =>
            import("./features/payment/payment.module").then(
                (m) => m.PaymentModule
            ),
        data: {title: "Payment"}
    },
    {
        path: "payments",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./features/payments/payments.module").then(
                (m) => m.PaymentsModule
            ),
        data: {title: "Payments"}
    },
    {
        path: "users/:id",
        canActivate:[PortalFeatureGuardService],
        canActivateChild: [AuthGuardService],
        loadChildren: () =>
            import("./features/user/user.module").then(
                (m) => m.UserModule
            ),
        data: {title: "User"}
    },
    {
        path: "users",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./features/users/users.module").then(
                (m) => m.UsersModule
            ),
        data: {title: "Users"}
    },
    {
        path: "sign-in",
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import("./features/sign-in/sign-in.module").then(
            (m) => m.SignInModule
        )
    },
    {
        path: "dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./features/dashboard/dashboard.module").then(
                (m) => m.DashboardModule
            ),
    },
    //redirect old urls to new paths to ensure book marks still work
    {
        path: "cart-details/:uid",
        redirectTo: "payments/:uid",
        pathMatch: "full",
    },
    {
        path: "user-details/personal-info/:id",
        redirectTo: "users/:id",
        pathMatch: "full",
    },
    {
        path: "user-details/payment-history/:id",
        redirectTo: "users/:id/payment-history",
        pathMatch: "full",
    },
    {
        path: "user-details/payment-methods/:id",
        redirectTo: "users/:id/payment-methods",
        pathMatch: "full",
    },
    {
        path: "user-details/notification-history/:id",
        redirectTo: "users/:id/notification-history",
        pathMatch: "full",
    },
    // fallbacks
    {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
    {
        path: "**",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "top",
            // will ensure that cancelling a back navigation event will not mess up navigation
            // This happens because cancelling via the canDeactivate occurs AFTER the url has been updated
            // If we use the default of 'replace', the navigation would indicate we had gone back even though we hadn't
            canceledNavigationResolution: 'computed',
            preloadingStrategy: PreloadAllModules
        }),
    ],
    exports: [RouterModule],
    declarations: [],
})
export class AppRoutingModule {}
