
<form role="form" class="layout-grid" (ngSubmit)="onSubmit_signIn(signInForm)" #signInForm="ngForm" aria-labelledby="aria_page_header">
    <ng-container *ngIf="!authService.adConfigModel.isAdEnabled || authService.tellerLogon">
        <mat-form-field appearance="standard">
            <mat-label>User ID</mat-label>
            <input matInput
                id="main-content-focus"
                [(ngModel)]="signInRequest.userId"
                name="UserId"
                [disabled]="userIdReadOnly"
                #userIdModel="ngModel"
                required
                maxlength="256">
            <mat-error *ngIf="userIdModel.invalid">{{validationService.getFieldErrorMessage(userIdModel.name, userIdModel, validationService.fieldErrors[userIdModel.name])}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard">
            <mat-label>Password</mat-label>
            <input matInput
                [(ngModel)]="signInRequest.password"
                autocomplete="current-password"
                name="Password"
                type="password"
                #passwordModel="ngModel"
                required
                maxlength="256">
            <mat-error *ngIf="passwordModel.invalid">{{validationService.getFieldErrorMessage(passwordModel.name, passwordModel)}}</mat-error>
        </mat-form-field>
    </ng-container>

    <button mat-flat-button color="accent" type="submit" [disabled]="loading">
        <span *ngIf="!signingIn">Sign In</span>
        <mat-spinner *ngIf="signingIn" diameter="20"></mat-spinner>
    </button>

    <button mat-button
        *ngIf="showSignOut"
        type="button"
        (click)="onClick_signOut(signInForm)"
        [disabled]="loading">
        <span *ngIf="!signingOut">Sign Out</span>
        <mat-spinner *ngIf="signingOut" diameter="20"></mat-spinner>
    </button>
</form>
